import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  padding: 2rem 4rem;
  max-width: 60rem;

  ${MEDIA.MIN_TABLET`
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
  `};
`;
